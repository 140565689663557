import useSWR from 'swr';

import { RetailersApiResponse, Store } from '@hultafors/shared/types';

interface UseOnlineStores {
  stores: Store[];
  loading: boolean;
  error?: any;
}
export const useOnlineStores = (): UseOnlineStores => {
  const { data, error, isValidating } = useSWR<RetailersApiResponse>(
    '/api/getOnlineStores',
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    },
  );

  return {
    error,
    loading: isValidating,
    stores: data?.stores || [],
  };
};
